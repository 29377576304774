export const HSRApoHisto = [
  {
    slug: 'acheron',
    score: 3310,
    usage: 45.38,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 3315,
    usage: 0.9,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 3310,
    usage: 1.69,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 3400,
    usage: 39.17,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 3317,
    usage: 1.9,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 3273,
    usage: 20.57,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 3181,
    usage: 2.85,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 3457,
    usage: 10.35,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 3348,
    usage: 17.54,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 3219,
    usage: 3.96,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 3000,
    usage: 0.02,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 3339,
    usage: 15.58,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 3516,
    usage: 52.25,
    role: 'dps',
    new: true
  },
  {
    slug: 'firefly',
    score: 3527,
    usage: 62.08,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 3324,
    usage: 40.27,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 3513,
    usage: 71.05,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 3375,
    usage: 2.84,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 3245,
    usage: 2.14,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 3265,
    usage: 0.6,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 3143,
    usage: 1.06,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 3355,
    usage: 3.94,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 3000,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 3335,
    usage: 23.71,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 3248,
    usage: 7.79,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 3279,
    usage: 1.12,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 3352,
    usage: 20.2,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'jing-yuan',
    score: 3157,
    usage: 1.84,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 3281,
    usage: 9.27,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 3277,
    usage: 18.22,
    role: 'specialist'
  },
  {
    slug: 'luka',
    score: 3311,
    usage: 0.25,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 3324,
    usage: 14.68,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 3287,
    usage: 1.61,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 3000,
    usage: 0.11,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 3338,
    usage: 19.85,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 3000,
    usage: 0.09,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 3452,
    usage: 5.29,
    role: 'specialist',
    new: true
  },
  {
    slug: 'natasha',
    score: 3311,
    usage: 0.26,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 3328,
    usage: 25.06,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 3125,
    usage: 0.77,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 3398,
    usage: 41.81,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 3498,
    usage: 88.35,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 3173,
    usage: 0.5,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 3226,
    usage: 2.78,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 3000,
    usage: 0.07,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 3313,
    usage: 26.52,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 3276,
    usage: 31.99,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 3245,
    usage: 0.48,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 3279,
    usage: 12.25,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 3407,
    usage: 22.86,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 3000,
    usage: 0.03,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 3504,
    usage: 73.32,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 3000,
    usage: 0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 3128,
    usage: 0.61,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 3122,
    usage: 0.65,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 3093,
    usage: 0.27,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 3000,
    usage: 0.22,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 3367,
    usage: 14.03,
    role: 'dps'
  }
];
